<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title class="headline primary white--text">
      {{ "Edit " + getpatientN }}
    </v-card-title>
    <v-card-text class="pt-3">
      <v-form ref="patientForm" v-model="formValid">
        {{/* Datos personales */ }}
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="name">
              First Name<span class="red--text">*</span>
            </label>
            <v-text-field
              @input="form.firstName = $event.toUpperCase()"
              v-model.trim="form.firstName"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="4" class="px-1">
            <label class="font-weight-medium" for="midlename">
              Middle Name
            </label>
            <v-text-field
              @input="form.midleName = $event.toUpperCase()"
              v-model.trim="form.midleName"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="lastname">
              Last Name <span class="red--text">*</span></label
            >
            <v-text-field
              @input="form.lastName = $event.toUpperCase()"
              v-model.trim="form.lastName"
              class="rounded"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="ssn"
              >SSN
              <template v-if="last4ssn">
                <span class="red--text">Current: (XXX-XX-{{ last4ssn }})</span>
              </template>
            </label>
            <v-text-field
              @input="form.ssn = $event.toUpperCase()"
              v-model="form.ssn"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="dateofbirth"
              >Date of Birth
            </label>
            <!-- <ma-date-picker v-model="form.birthDate" past :editable="true" /> -->
            <template v-if="birthDate != ''">
              <DobPicker
                :dateInitial="birthDate"
                @dateDOB="form.birthDate = $event"
              />
            </template>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="gender">Gender </label>
            <v-select
              :items="genders"
              item-text="label"
              item-value="value"
              v-model="form.isMale"
              outlined
              dense
            ></v-select>
          </v-col>
          <!--  <v-col cols="12" sm="3" class="px-1">
            <label for="phone">Phone <span class="red--text">*</span> </label>
             <v-text-field
  @input="homePhone=$event.toUpperCase()"
              v-model="homePhone"
              prepend-inner-icon="mdi-phone"
              autocomplete="tel"
              outlined
              dense
            />
          </v-col> -->
          <!--   <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="email">Email </label>
             <v-text-field
  @input="form.email=$event.toUpperCase()"
              v-model.number="form.email"
              type="email"
              :rules="[rules.email]"
              autocomplete="email"
              prepend-inner-icon="mdi-email"
              outlined
              dense
            />
          </v-col> -->
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="legal">Legal Status </label>
            <v-select
              :items="legalstatus"
              v-model="form.legalStatus"
              item-text="label"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="cernatur">ID Number </label>
            <br />

            <v-text-field
              @input="form.idNumber = $event.toUpperCase()"
              outlined
              v-model="form.idNumber"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="cernatur"
              >Expiration Date (if apply)
            </label>
            <ma-date-picker v-model="form.expDate" past :editable="true" />
          </v-col>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="cernatur"
              >Is Married?
            </label>
            <v-select
              :items="civilstatus"
              item-text="label"
              item-value="value"
              outlined
              dense
              v-model="form.isMaried"
            ></v-select>
          </v-col>
        </v-row>

        {{/* state, city and zip */ }}

        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address">Address </label>
            <v-text-field
              @input="form.address.addressLine1 = $event.toUpperCase()"
              v-model="form.address.addressLine1"
              prepend-inner-icon="mdi-map-marker"
              dense
              autocomplete="street-address"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field
              @input="form.address.addressLine2 = $event.toUpperCase()"
              v-model="form.address.addressLine2"
              dense
              autocomplete="apto-address"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete
              v-model="state"
              :items="states"
              item-text="name"
              item-value="id"
              :loading="loadingStates"
              autocomplete="off"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete
              :disabled="stateCities.length == 0"
              v-model.number="form.address.city"
              :items="stateCities"
              item-text="name"
              item-value="id"
              :loading="loadingCities"
              autocomplete="off"
              mandatory
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field
              v-model="form.address.zipCode"
              :rules="[rules.zip]"
              autocomplete="postal-code"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address"
              >Mailing Address (if different)
            </label>
            <v-text-field
              @input="form.mailAddress.addressLine1 = $event.toUpperCase()"
              prepend-inner-icon="mdi-map-marker"
              v-model="form.mailAddress.addressLine1"
              dense
              autocomplete="street-mail"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field
              @input="form.mailAddress.addressLine2 = $event.toUpperCase()"
              v-model="form.mailAddress.addressLine2"
              dense
              autocomplete="apto-mail"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete
              :items="states"
              v-model="state1"
              item-text="name"
              item-value="id"
              :loading="loadingStates"
              autocomplete="off"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete
              :disabled="stateCities1.length == 0"
              :items="stateCities1"
              item-text="name"
              item-value="id"
              :loading="loadingCities1"
              autocomplete="off"
              v-model="form.mailAddress.city"
              mandatory
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field
              v-model="form.mailAddress.zipCode"
              autocomplete="postal-code"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <!--   <v-row no-gutters>
          <v-col cols="12" sm="4" class="cerofnat"
            ><label class="font-weight-medium" for="cernatur"
              >Employed or Self-Employed
            </label>
            <br />
            <label class="font-weight-medium" for="ifemp"
              >(if Employed, Company Name)
            </label>
             <v-text-field
  @input="form.employedCompany=$event.toUpperCase()" outlined v-model="form.employedCompany" dense></v-text-field
          ></v-col>
          <v-col cols="12" sm="3" class="px-1 pt-4 mt-2"
            ><label class="font-weight-medium" for="workphone">Work Phone </label>
             <v-text-field
  @input="form.workPhone=$event.toUpperCase()"
              prepend-inner-icon="mdi-phone"
              outlined
              :rules="[rules.phone1]"
              v-model="form.workPhone"
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="3" class="pr-1 pt-4 mt-2"
            ><label class="font-weight-medium" for="posocc">Position/Occupation </label>
             <v-text-field
  @input="form.ocupation=$event.toUpperCase()" v-model="form.ocupation" outlined dense></v-text-field
          ></v-col>
          <v-col cols="12" sm="2" class="pt-4 mt-2"
            ><label class="font-weight-medium" for="annualinco">Annual Income </label>
             <v-text-field
  @input="form.anualIncome=$event.toUpperCase()" v-model="form.anualIncome" outlined dense></v-text-field
          ></v-col>
        </v-row> -->
        <v-row no-gutters>
          <!--   <v-col cols="12" sm="3"
            ><label class="font-weight-medium" for="cprefer">Contact Preference </label>
            <v-select
              outlined
              dense
              v-model="form.contactPreference"
              :items="['PHONE', 'EMAIL']"
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="3" class="px-1"
            ><label class="font-weight-medium" for="preferlang"
              >Preferred Language
            </label>
            <v-select
              outlined
              dense
              :items="langs"
              item-text="label"
              item-value="value"
              v-model="form.preferLanguage"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class=""
            ><label class="font-weight-medium" for="source"
              >Source <span class="red--text">*</span>
            </label>
            <v-select
              :items="references"
              item-text="name"
              item-value="id"
              v-model="form.sourceId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Company </label>
            <v-select
              :items="companies"
              :disabled="!isAdmins && !isSupervisor"
              item-text="name"
              item-value="id"
              v-model="companySelect"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source"
              >Company State
            </label>
            <v-select
              :items="companyStates"
              :loading="loadingCompanyStates"
              item-text="state.name"
              :disabled="
                companySelect == null ||
                companyStates.length == 0 ||
                (!isAdmins && !isSupervisor)
              "
              v-model="form.companyStateId"
              item-value="state.id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Metal Plan </label>
            <v-select
              :items="metalplan"
              item-text="label"
              item-value="value"
              v-model="form.policyPlan"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source">Binder Pay </label>
            <v-radio-group
              v-model="form.binderDay"
              class="mt-0"
              mandatory
              row
              dense
              hide-details
            >
              <v-radio :value="true" :label="$t('Yes')" />
              <v-radio :value="false" :label="$t('No')"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source"
              >Police Number
            </label>
            <v-text-field
              @input="form.polizeNumber = $event.toUpperCase()"
              v-model="form.polizeNumber"
              outlined
              dense
              :disabled="!isAdmins && !isSupervisor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source">Premium </label>
            <v-text-field v-model="form.premium" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source"
              >Effective Date<span class="red--text">*</span>
            </label>
            <template v-if="effectiveDate != ''">
              <EffectivePicker
                :disabled="!isAdmins && !isSupervisor"
                :dateInitial="effectiveDate"
                @dateDOB="form.effectiveDate = $event"
              />
            </template>
          </v-col>

          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source"
              >Members<span class="red--text">*</span>
            </label>
            <v-text-field
              v-model="members"
              outlined
              dense
              :disabled="!isAdmins && !isSupervisor"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source"
              >Status <span class="red--text">*</span>
            </label>
            <v-select
              item-text="label"
              item-value="value"
              :items="policyStates"
              v-model="form.polizeStatus"
              @change="gotosetStatus()"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source">OP Status </label>

            <v-select
              item-text="name"
              item-value="id"
              :items="renewalStatus"
              v-model="form.renewalStatusNomId"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source"
              >Payment Status
            </label>

            <v-select
              item-text="name"
              item-value="value"
              :disabled="!isAdmins && !isSupervisor"
              :items="paymentStatus"
              v-model="form.paymentStatus"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="source"
              >NPN <span class="red--text">*</span></label
            >

            <v-select
              item-text="name"
              item-value="id"
              :items="npns"
              v-model="form.npnNomId"
              outlined
              dense
            ></v-select>
          </v-col>

          <template v-if="isAdmins">
            <v-col cols="12" sm="3" class="pl-1">
              <label class="font-weight-medium" for="source">Pay To </label>

              <v-select
                item-text="name"
                item-value="id"
                :items="paysTo"
                v-model="form.payToNomId"
                outlined
                dense
              ></v-select>
            </v-col>
          </template>

          <v-col cols="12" sm="4" class="pl-1">
            <label class="font-weight-medium" for="tags">Tags </label>
            <v-autocomplete
              item-text="name"
              item-value="id"
              :items="clientsTags"
              prepend-inner-icon="mdi-magnify"
              multiple
              :disabled="clientsTags.length == 0"
              v-model="tagIds"
              outlined
              dense
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ tagIds.length - 1 }} others)
                </span>
              </template>
              <template v-slot:prepend-inner>
                <template v-if="!checkAllTags && clientsTags.length != 0">
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="success"
                          @click="checkAllTags = true"
                          v-text="'mdi-check-all'"
                        ></v-icon>
                      </template>
                      <span>Select All Tags</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>

      <v-dialog
        v-model="setStatusObject.dialog"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <template v-if="setStatusObject.value">
              Status Change:
              {{ statusShow(oldStatus) + " to " + policySelectChange }}
            </template>
          </v-card-title>
          <v-card-text>
            <template v-if="policySelectChange == 'Cancelled'">
              <div class="mt-2">
                <label for="source">Cancellation Date: </label>
                <DobPicker class="mt-1" @dateDOB="cancellationDate = $event" />
              </div>
              <v-select
                class="mt-3"
                item-text="name"
                label="Motive"
                item-value="value"
                :items="motives"
                v-model="polizeCancelledMotive"
                outlined
                dense
              ></v-select>
            </template>

            <v-textarea
              v-model.trim="setStatusObject.note"
              class="my-3"
              placeholder="Write a note"
              :disabled="loading"
              outlined
              counter="240"
            />
          </v-card-text>
          <template v-if="!validNote">
            <div class="mx-3">
              <v-alert dense outlined type="error">
                The note must be between 5 and 240 characters
              </v-alert>
            </div>
          </template>

          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="error"
              :disabled="setStatusObject.loading"
              text
              @click="cancelSetStatus"
              >cancel</v-btn
            >
            <v-btn
              color="success"
              :loading="setStatusObject.loading"
              :disabled="setStatusObject.loading || !validNote"
              @click="setStatusObject.dialog = false"
              text
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-alert class="mx-4" type="error" :value="validEditForm == false">
      {{
        !validDate
          ? "Invalid Date of Birth"
          : "All fields with ( * ) are required"
      }}
    </v-alert>
    <v-divider class="mb-3" />

    <v-card-actions class="justify-end">
      <v-btn
        color="error"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="cancel"
      >
        Cancel
      </v-btn>
      <!--  <v-btn
        color="grey"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="$refs.patientForm.reset()"
      >
        Reset
      </v-btn> -->
      <v-btn
        class="ml-3"
        color="primary"
        :disabled="loading || !validEditForm"
        :loading="loading"
        width="100"
        depressed
        rounded
        @click="update"
      >
        UPDATE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapState } from "vuex";
import City from "@/models/City";
import { getAPI } from "@/api/axios-base";
import store from "@/store/index";
import cleanObject from "@/utils/cleanObject";
import rules from "@/components/account/rules";
import isEqual from "lodash/isEqual";
import { AxiosError } from "axios";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import Worker from "@/models/Worker";
import moment from "moment";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { cleanPhoneNumber } from "@/utils/formatPhone";

import DobPicker from "@/components/MaDatePicker/DobPicker.vue";
import EffectivePicker from "@/components/MaDatePicker/EffectivePicker.vue";

export default Vue.extend({
  components: { Errors, MaDatePicker, DobPicker, EffectivePicker },
  name: "form-update-patient",
  data() {
    return {
      formValid: false,
      birthDateMenu: false,
      loadingCompanyStates: false,
      companySelect: null,
      companyStates: [],

      rules: {
        email: rules.emailnull,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      stateCities: [],
      loadingCities: false,
      state: 23,
      stateCities1: [],
      loadingCities1: false,
      state1: 23,
      polizeCancelledMotive: null,
      cancellationDate: null,
      setStatusObject: {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      },
      loading: false,
      showErrors: false,
      errorMessage: "",
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      year: "",
      month: "",
      civilstatus: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      legalstatus: [
        { label: "Citizen", value: "CITIZEN" },
        { label: "Permanent Resident", value: "PERMANENT_RESIDENT" },
        { label: "Temporal Resident", value: "TEMPORAL_RESIDENT" },
      ],

      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
      last4ssn: "",
      isAdmins: false,
      oldStatus: "",
      // homePhone: "",
      loadingRenewalS: false,
      npnloading: false,
      paytoloading: false,
      paymentLoading: false,
      renewalStatus: [],
      paysTo: [],
      paymentStatus: [
        { name: "Pay", value: "PAY" },
        { name: "Reconciliation", value: "RECONCILIATION" },
        { name: "Member Problem", value: "MEMBER_PROBLEM" },
        { name: "Charge Back", value: "CHARGE_BACK" },
        { name: "End", value: "END" },
        { name: "Review", value: "REVIEW" },
      ],
      motives: [
        { name: "No Aor", value: "NO_AOR" },
        { name: "Other Party", value: "OTHER_PARTY" },
        { name: "No Renewal", value: "NO_RENEWAL" },
      ],
      npns: [],
      checkAllTags: false,
      tagIds: null,
      tagsAdmins: [],
      birthDate: "",
      effectiveDate: "",
      members: null,
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",
        birthDate: "",
        ssn: "",
        // homePhone: "",
        preferLanguage: "ENGLISH",
        isMale: false,
        sourceId: 0,
        tagIds: null,
        renewalStatusNomId: null,
        emergencyContact: "",
        emergencyPhone: "",
        emergencyRelation: "",
        // email: "",
        premium: null,
        leadUuid: "",
        policyPlan: "",
        effectiveDate: "",
        agentUuid: "",
        isMaried: false,
        legalStatus: "",
        binderDay: false,
        employedCompany: "",
        companyId: null,
        companyStateId: null,
        polizeNumber: null,
        polizeStatus: null,
        npnNomId: null,
        payToNomId: null,
        paymentStatus: null,
        workPhone: "",
        idNumber: "",
        expDate: "",
        anualIncome: "",
        membersByMonth:[0,0,0,0,0,0,0,0,0,0,0,0],
        ocupation: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
        mailAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
      },

      formatNumber: (v) => /^[0-9]+$/.test(v) || this.$t("formatError"),
      validateYear: (v) => {
        if (v == null) {
          return "";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userYear = parseInt(v);

        if (v.length != 4) {
          return this.$t("lengthYearError");
        }

        if (userYear > currentYear) {
          return true;
        }

        return this.$t("yearWrong");
      },
      formatCvc: (v) =>
        /^[0-9][0-9]{2,3}$/.test(v) || this.$t("formatCvcError"),

      validateCard: (v) => {
        const isCard =
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

        return isCard.test(v) || this.$t("wrongCard") + "";
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "isSupervisor",
      "isAdmin",
      "isSuper",
    ]),
    ...mapState("crmMedicFormModule", [
      "states",
      "loadingStates",
      "patientDetail",
    ]),
    ...mapState(["profile"]),
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
      "tags",
    ]),
    clientsTags() {
      let lt = this.tags.sort((a, b) => {
        return a.isLead - b.isLead;
      });
      lt = lt.filter((r) => r.status == "ACTIVE");
      if (this.isAdmins) {
        return lt;
      } else {
        let arraytags = lt;
        for (let index = 0; index < this.tagsAdmins.length; index++) {
          const element = this.tagsAdmins[index].tagNom;
          arraytags = arraytags.filter((at) => at.id != element.id);
        }
        return arraytags;
      }
    },

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },

    months() {
      return [
        {
          value: 1,
          name: this.$t("January"),
        },
        {
          value: 2,
          name: this.$t("February"),
        },
        {
          value: 3,
          name: this.$t("March"),
        },
        {
          value: 4,
          name: this.$t("April"),
        },
        {
          value: 5,
          name: this.$t("May"),
        },
        {
          value: 6,
          name: this.$t("June"),
        },
        {
          value: 7,
          name: this.$t("July"),
        },
        {
          value: 8,
          name: this.$t("August"),
        },
        {
          value: 9,
          name: this.$t("September"),
        },
        {
          value: 10,
          name: this.$t("October"),
        },
        {
          value: 11,
          name: this.$t("November"),
        },
        {
          value: 12,
          name: this.$t("December"),
        },
      ];
    },

    /* validPhonePrimary() {
      const path = /^[1-9][0-9]{9}$/;
      if (path.test(this.form.homePhone)) {
        return true;
      }
      return false;
    }, */
    validDate() {
      if (this.form.birthDate == "" || this.form.birthDate == null) {
        return false;
      }
      const d = moment(this.form.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },

    validEditForm() {
      if (
        this.validDate &&
        this.form.sourceId &&
        this.members &&
        this.members != 0 &&
        this.form.npnNomId != null &&
        this.form.npnNomId != undefined &&
        this.form.effectiveDate
      ) {
        return true;
      }
      return false;
    },

    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
            /* { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ]
        : [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            { value: "BLACKLIST", label: "Blacklist" },
            { value: "END", label: "End" },
            /*   { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ];

      if (!this.form.polizeNumber) {
        const x = states.find((s) => s.value == this.form.polizeStatus);
        return [
          x,
          { value: "CANCELLED", label: "Cancelled" },
          { value: "BLACKLIST", label: "Blacklist" },
        ];
      }

      return states;
    },

    policySelectChange() {
      if (
        this.setStatusObject.value == null ||
        this.setStatusObject.value == undefined ||
        this.setStatusObject.value == ""
      ) {
        return "";
      }
      const find = this.policyStates.find(
        (p) => p.value == this.setStatusObject.value
      );
      return find ? find.label : "";
    },
  },
  watch: {
    /*  homePhone: {
      handler(val) {
        if (val != null) {
          this.homePhone = cleanPhoneNumber(this.homePhone);
          if (this.homePhone[0] == "1") {
            this.homePhone = this.homePhone.slice(1);
          }
          this.form.homePhone = this.homePhone;
        }
      },
    }, */
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },

    state1: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities1 = [];
        this.loadingCities1 = false;

        return;
      }

      this.loadingCities1 = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities1 = false;
        this.stateCities1 = cities;
      });
    },
    companySelect(val) {
      if (val != null) {
        this.form.companyId = val;
        this.getCompanyState(val);
      }
    },
    tagIds: {
      handler(val) {
        this.form.tagIds = val;
        if (val && val.length != this.clientsTags.length) {
          this.checkAllTags = false;
        } else if (
          val &&
          val.length == this.clientsTags.length &&
          this.checkAllTags == false
        ) {
          this.checkAllTags = true;
        }
      },
    },

    checkAllTags(val) {
      if (val) {
        if (
          this.tagIds == null ||
          this.clientsTags.length != this.tagIds.length
        ) {
          this.tagIds = this.clientsTags.map((t) => {
            return t.id;
          });
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.isAdmin || this.isSuper) {
      this.isAdmins = true;
      this.listPayto();
    }
    await this.getPatientData();
    await this.actListStates();
    await this.getRenewalStatus();
    await this.listNpns();

    await this.actListReferences();
    await this.actListTags();
    await this.actListCompaniesNom();

    this.loading = false;
  },
  destroyed() {
    localStorage.removeItem("customerUuid");
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",

      "actGetMedicalLocations",
    ]),
    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
      "actListTags",
    ]),

    statusShow(item) {
      if (item) {
        const st = PolicyStatus[item];
        return st;
      }

      return "-";
    },

    gotosetStatus() {
      if (this.form.polizeStatus != this.oldStatus) {
        this.setStatusObject = {
          dialog: true,
          value: this.form.polizeStatus,
          loading: false,
          note: "",
        };
      } else {
        this.setStatusObject = {
          dialog: false,
          value: null,
          loading: false,
          note: "",
        };
      }
    },
    cancelSetStatus() {
      this.polizeCancelledMotive = null;
      this.cancellationDate = null;
      this.setStatusObject = {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      };
      this.form.polizeStatus = this.oldStatus;
    },

    update() {
      const { form: body } = this;
      let { ...rest } = body;

      if (rest.birthDate) {
        rest.birthDate = moment(rest.birthDate).toISOString();
      }
      if (rest.effectiveDate) {
        rest.effectiveDate = moment(rest.effectiveDate).toISOString();
      }
      if (rest.expDate) {
        rest.expDate = new Date(rest.expDate).toISOString();
      }
      /*   if (rest.homePhone != "" && rest.homePhone != null) {
        rest.homePhone = "+1" + rest.homePhone;
      } */

      if (this.members) {
        const m = Number(moment().format('MM'))
        rest.membersByMonth[m-1]= Number(this.members)
       
      }
      if (rest.premium) {
        rest.premium = Number(rest.premium);
      }
      if (rest.workPhone) {
        rest.workPhone = "+1" + rest.workPhone;
      }
      if (rest.companyId) {
        rest.companyId = Number(rest.companyId);
      }
      if (rest.npnNomId) {
        rest.npnNomId = Number(rest.npnNomId);
      }
      if (rest.payToNomId) {
        rest.payToNomId = Number(rest.payToNomId);
      }

      if (rest.companyStateId) {
        rest.companyStateId = Number(rest.companyStateId);
      }

      rest.address.state = this.state.toString();
      rest.mailAddress.state = this.state1.toString();
      if (rest.sourceId) {
        rest.sourceId = Number(rest.sourceId);
      }
      if (rest.sourceId == 0) {
        delete rest.sourceId;
      }
      if (
        rest.mailAddress.addressLine1 == "" ||
        rest.mailAddress.addressLine1 == null ||
        rest.mailAddress.addressLine1 == undefined
      ) {
        delete rest.mailAddress;
      } else {
        rest.mailAddress.city = rest.mailAddress.city.toString();
      }
      if (
        rest.address.addressLine1 == "" ||
        rest.address.addressLine1 == null ||
        rest.address.addressLine1 == undefined
      ) {
        delete rest.address;
      } else {
        rest.address.city = rest.address.city.toString();
      }

      if (
        rest.polizeStatus != this.oldStatus &&
        this.setStatusObject.note != null &&
        this.setStatusObject.note != ""
      ) {
        rest = { ...rest, noteForStatusChange: this.setStatusObject.note };
        if (rest.polizeStatus == "CANCELLED") {
          rest = {
            ...rest,
            polizeCancelledMotive: this.polizeCancelledMotive,
            cancellationDate: moment(this.cancellationDate).toISOString(),
          };
        } else {
          this.polizeCancelledMotive = null;
          this.cancellationDate = null;
        }
      }

      if (rest.polizeStatus == this.oldStatus) {
        delete rest.polizeStatus;
      }
      rest.firstName = rest.firstName.trim();

      rest.lastName = rest.lastName.trim();

      this.loading = true;
      // Remove empty values

      rest = this.cleanNull(rest);
      rest.midleName = rest.midleName ? rest.midleName?.trim() : "";
      if (!this.isAdmins && !this.isSupervisor) {
        delete rest.companyId;
        delete rest.companyStateId;
        delete rest.membersByMonth;
        delete rest.effectiveDate;
        delete rest.polizeNumber;
      }

      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, {
          ...rest,
        })
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientUpdated"));

          this.loading = false;
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: this.$route.params.uuid },
          });
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async getPatientData() {
      let patient;
      if (this.patientDetail == null) {
        patient = (
          await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
        ).data;
      } else {
        patient = this.patientDetail;
      }
      this.form.isMaried = patient?.isMaried;
      //1ra line
      this.form.firstName = patient?.firstName?.toUpperCase();
      this.form.midleName = patient?.midleName?.toUpperCase();
      this.form.lastName = patient?.lastName?.toUpperCase();

      //2da line
      this.last4ssn = patient?.ssn?.toUpperCase();
      this.form.birthDate = patient?.birthDate;
      this.birthDate = patient?.birthDate;

      this.form.isMale = patient?.isMale;
      /*   this.form.homePhone = patient?.homePhone
        ? patient?.homePhone.replace("+1", "")
        : ""; */
      //this.homePhone = this.form.homePhone;
      //this.form.email = patient?.email ? patient?.email : "";

      //3ra line
      this.form.legalStatus = patient?.legalStatus;
      this.form.idNumber = patient?.idNumber?.toUpperCase();
      this.form.npnNomId = patient?.npnNomId;
      this.form.payToNomId = patient?.payToNomId;
      this.form.paymentStatus = patient?.paymentStatus;
      this.form.expDate = patient?.expDate
        ? patient?.expDate.split("T")[0]
        : "";

      //4ta line
      const stateId = patient.address ? Number(patient?.address.state) : 23;
      this.state = stateId;
      this.loadingCities = true;
      const cities = await this.actGetCityByState(stateId);
      this.stateCities = cities;
      this.form.address.addressLine1 = patient.address
        ? patient?.address.addressLine1?.toUpperCase()
        : "";
      this.form.address.addressLine2 = patient?.address
        ? patient.address.addressLine2?.toUpperCase()
        : "";
      this.form.address.city = patient?.address
        ? Number(patient.address.city)
        : 0;
      this.form.address.zipCode = patient?.address
        ? patient.address.zipCode
        : "";
      this.loadingCities = false;

      //5ta line
      const stateId1 =
        patient.mailAddress && Number(patient?.mailAddress.state) != 0
          ? Number(patient?.mailAddress.state)
          : 23;
      this.state1 = stateId1;
      this.loadingCities1 = true;
      const cities1 = await this.actGetCityByState(stateId1);
      this.stateCities1 = cities1;
      this.form.mailAddress.addressLine1 = patient.mailAddress
        ? patient?.mailAddress.addressLine1?.toUpperCase()
        : "";
      this.form.mailAddress.addressLine2 = patient?.mailAddress
        ? patient.mailAddress.addressLine2?.toUpperCase()
        : "";
      this.form.mailAddress.city = patient?.mailAddress
        ? Number(patient.mailAddress.city)
        : 0;
      this.form.mailAddress.zipCode = patient?.mailAddress
        ? patient.mailAddress.zipCode
        : "";
      this.loadingCities1 = false;

      //6ta line
      // this.form.employedCompany = patient?.employedCompany;
      /*   this.form.workPhone = patient?.workPhone
        ? patient?.workPhone.replace("+1", "")
        : ""; */
      // this.form.ocupation = patient?.ocupation;
      // this.form.anualIncome = patient?.anualIncome;

      //7ma line
      this.form.contactPreference = patient?.contactPreference;
      this.form.preferLanguage = patient?.preferLanguage;
      this.form.sourceId = patient?.sourceNom ? patient.sourceNom.id : 0;

      //8va line
      this.companySelect = patient.company ? patient.company.id : null;
      this.form.companyStateId = patient.companyState
        ? patient.companyState.id
        : null;
      this.form.policyPlan = patient?.policyPlan;
      this.form.binderDay = patient?.binderDay;

      //9na line
      this.form.polizeNumber = patient.polizeNumber
        ? patient.polizeNumber?.toUpperCase()
        : null;
      this.form.effectiveDate = patient.effectiveDate
        ? patient.effectiveDate.split("T")[0]
        : "";
      this.effectiveDate = patient.effectiveDate
        ? patient.effectiveDate.split("T")[0]
        : moment().startOf("month").add(1, "month").toISOString();
      this.oldStatus = patient.polizeStatus ? patient.polizeStatus : null;
      this.form.polizeStatus = patient.polizeStatus
        ? patient.polizeStatus
        : null;
      this.form.renewalStatusNomId = patient.renewalStatusNomId
        ? patient.renewalStatusNomId
        : null;
      if (patient?.patientTagsNom) {
        if (this.isAdmins) {
          this.tagIds = patient?.patientTagsNom.map((tn) => {
            return tn.tagNom.id;
          });
        } else {
          this.tagsAdmins = patient?.patientTagsNom.filter(
            (ptn) => ptn.asAdmin
          );
          const tagsNoAdmin = patient?.patientTagsNom.filter(
            (ptn) => !ptn.asAdmin
          );
          this.tagIds = tagsNoAdmin.map((tn) => {
            return tn.tagNom.id;
          });
        }
      } else {
        this.tagIds = null;
      }
   
      this.form.membersByMonth=patient?.membersByMonth ? patient?.membersByMonth:[0,0,0,0,0,0,0,0,0,0,0,0];
      const m =Number (moment().format('MM'))
      this.members =  this.form.membersByMonth[m-1]
      this.form.premium = patient.premium ? patient.premium : null;
    },
    deletePhone() {
      this.loading = true;
      getAPI
        .put("/patient/setNull/" + this.$route.params.uuid, {
          emergencyPhone: false,
        })
        .then((res) => {
          this.loading = false;
          this.form.emergencyPhone = "";
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    reset() {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.loading = false;
      if (localStorage.getItem("customerUuid")) {
        this.$router.push(
          `/customers/details/${localStorage.getItem("customerUuid")}`
        );
      } else {
        this.$router.push({
          name: "Patient ddetails",
          params: { uuid: this.$route.params.uuid },
        });
      }
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getExpiration(month, year) {
      const date = moment(year);
      date.startOf("year").add(month - 1, "month");

      return date.toISOString();
    },
    getYears() {
      const yearsList = [];
      const currentYear = new Date().getFullYear();

      for (let i = 1; i <= 10; i++) {
        yearsList.push(currentYear + i);
      }

      return yearsList;
    },

    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getRenewalStatus() {
      this.loadingRenewalS = true;
      getAPI("/renewall-status/list")
        .then((res) => {
          this.loadingRenewalS = false;
          this.renewalStatus = res.data.filter((r) => r.status == "ACTIVE");
        })
        .catch((error) => {
          this.loadingRenewalS = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    listNpns() {
      this.npnloading = true;

      getAPI(`/npn-nom/list`)
        .then((res) => {
          this.npns = res.data.filter((r) => r.status == "ACTIVE");

          this.npnloading = false;
        })
        .catch((error) => {
          this.npnloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    listPayto() {
      this.paytoloading = true;

      getAPI(`/pay-to-nom/list`)
        .then((res) => {
          this.paysTo = res.data.filter((r) => r.status == "ACTIVE");

          this.paytoloading = false;
        })
        .catch((error) => {
          this.paytoloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.cerofnat {
  padding-top: 2px !important;
}
</style>
<i18n>
{
  "en": {
    "title": "Add a credit card",
    "cardNumber": "Credit card number",
    "cardHolder": "Card Holder",
    "expirationDate": "Expiration date",
    "cvc": "Card verification code (CVC)",
    "addCard": "Add card",
    "confirm": "Confirm",
    "year": "Year",
    "month": "Month",
    "formatError": "Wrong format, type a number",
    "yearWrong": "The year provided have to major to current year",
    "lengthYearError": "The year must have four characters",
    "wrongCard": "You must use one of the following cards: Visa, MasterCard, American Express, Diners Club, Discover or JCB",
    "formatCvcError": "Invalid CVC, only 3 or 4 digits are accepted",
    "cardSelected": "The card has been added, select it from the list",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "addOne": "Card Data",
    "epayaddOne": "USAePay Card Data"
  },
  "es": {
    "title": "Agregar tarjeta de crédito",
    "cardNumber": "Número de tarjeta de crédito",
    "expirationDate": "Fecha de expiración",
    "cvc": "CVC inválido, solo se aceptan 3 o 4 dígitos",
    "addCard": "Agregar tarjeta",
    "confirm": "Confirmar",
    "year": "Año",
    "month": "Mes",
    "formatError": "Formato erróneo, escriba un número",
    "yearWrong": "El año proporcionado debe ser mayor al año actual.",
    "lengthYearError": "El año debe tener cuatro caracteres",
    "wrongCard": "Debe usar una de las siguientes tarjetas: Visa, MasterCard, American Express, Diners Club, Discover o JCB",
    "formatCvcError": "CVC inválido",
    "cardSelected": "La tarjeta ha sido añadida, selecciónala de la lista",
    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiebre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",
    "addOne": "Datos de la tarjeta",
     "epayaddOne": "Datos de la tarjeta USAePay"
  }
}
</i18n>
